import React from 'react';
import PropTypes from 'prop-types';
import BaseComponent, { FetchAPIPromise, PostAPIPromise, DeleteAPIPromise } from '../BaseComponent.js';
import DataGrid, { FilterRow, Editing, RequiredRule, Column, Lookup, FormItem, Export } from 'devextreme-react/data-grid';
import CustomStore from 'devextreme/data/custom_store';

export class WorkOrderResourcesEquipmentContainer extends BaseComponent {
    static propTypes = {
        workOrder: PropTypes.object
    };

    constructor(props) {
        super(props);
        this.workOrderName = props.workOrder.workOrderName;
        this.state = {
            devices: [],
            employees: []
        };
    }

    customDataSource = new CustomStore({
        key: ['workOrderID', 'employeeID', 'equipmentID'],
        load: (loadOptions) => {
            console.log('Load', loadOptions);
            console.log(this.props.workOrder.id);
            console.log(this.props.workOrder);

            if (this.props.workOrder.id) {
                return FetchAPIPromise('WorkOrderResourcesEquipment/' + this.props.workOrder.id);
            }
        },

        insert: (values) => {
            values.workOrderId = this.props.workOrder.id;

            var equipmentName = this.state.devices.find(e => e.id === values.equipmentID);
            values.equipmentName = equipmentName.assetNumber;
            values.contactNumber = equipmentName.phoneNumber;

            var equip = parseInt(values.equipmentID);
            values.equipmentID = equip;

            return PostAPIPromise('WorkOrderResourcesEquipment', values);
        },
        remove: (key) => {
            var queryString = 'equipmentId=' + key.equipmentID + '&workOrderId=' + key.workOrderID + '&employeeId=' + key.employeeID;
            return DeleteAPIPromise('WorkOrderResourcesEquipment/DeleteByIds', queryString);
        }
    });

    GetDropDownData = async () => {

        var deviceListData = await this.FetchAPI('Equipment');

        console.log('Devices');

        var employeeListData = await this.FetchAPI('WorkOrderResourcesEmployeeList/GetByWorkOrderIdSimple/' + this.props.workOrder.id);

        this.setState({
            devices: deviceListData,
            employees: employeeListData
        });
    }

    render() {
        console.log("Beginning render");
        return (
            <div style={{ margin: "10px" }}>
                <DataGrid dataSource={this.customDataSource} showBorders={true}
                    allowColumnResizing={true} onExporting={this.onExporting}>
                    <Export enabled={true} fileName={"equipment" + this.workOrderName} />
                    <Editing mode="popup" allowAdding={false} allowDeleting={false} />
                    <FilterRow visible={true} />

                    <Column caption="Employee Name" dataField="employeeID" >
                        <RequiredRule />
                        <Lookup dataSource={this.state.employees} displayExpr="fullName" valueExpr="employeeId" />
                    </Column>
                    <Column caption="Device Name" dataField="equipmentName" />
                    <Column caption="Contact Number" dataField="contactNumber">
                        <FormItem visible={false} />
                    </Column>
                    <Column caption="Last Access" dataField="updatedAt" dataType="datetime" format="MM/dd/yy HH:mm" />
                </DataGrid>
            </div>
        );
    }
}