import React from 'react';
import BaseComponent, { FetchAPIPromise, withParams } from "../BaseComponent";
import Form, { Item, EmptyItem, Label, TabbedItem, Tab, ButtonItem, RequiredRule } from 'devextreme-react/form';
import { LoadPanel } from 'devextreme-react/load-panel';
import DataGrid, { Column, Editing, FilterRow, Pager, Paging, FormItem, Lookup } from 'devextreme-react/data-grid';
import SelectBox from 'devextreme-react/select-box';
import TreeView from 'devextreme-react/tree-view';
import { alert, confirm } from 'devextreme/ui/dialog';
import { Link } from 'react-router-dom';
import * as moment from 'moment';
import { Popup } from 'devextreme-react/popup';
import CustomStore from 'devextreme/data/custom_store';
import { msalAuth } from '../../msal/MsalAuthProvider';

// TODO: Validate only one org group per contract?
class EmployeeEditContainer extends BaseComponent {
    static displayName = EmployeeEditContainer.name;

    constructor(props) {
        super(props);
        this.state = {
            employeeId: '',
            employeeData: {},
            schedules: [],
            selectedSchedule: "",
            offices: [],
            employeeTags: [],
            genders: [],
            supervisors: [],
            timeTypes: [],
            categories: [],
            active: false,
            jobProfiles: [],
            email: "",

            changeHistoryVisible: false,
            message: 'Loading' 
        };
    }

    componentDidMount = async () => {
        super.componentDidMount();
        //console.log('ComponentDidMount');
        //console.log(this.props.params);

        const { id } = this.props.params

        await this.setState({ employeeId: id });

        await this.fetchData();
    }

    GetComponentPageName = () => {
        return (this.state.employeeData.employeeNo ? ("#" + this.state.employeeData.employeeNo + " Employee Edit") : 'Create Employee');
    }

    GetDropDownData = async () => {
        const genderData = await this.FetchAPI('DictionaryGender');
        const officeData = await this.FetchAPI('Office');
        const supervisorsData = await this.FetchAPI('SupervisoryOrgs');
        const employeeTagsData = await this.FetchAPI('DictionaryEmployeeTags');
        const timeTypeData = await this.FetchAPI('DictionaryTimeType');
        const categoryData = await this.FetchAPI('DictionaryEmployeeCategory');

        this.setState({
            genders: genderData,
            offices: officeData,
            supervisors: supervisorsData,
            employeeTags: employeeTagsData,
            timeTypes: timeTypeData,
            categories: categoryData
        });
    }

    GetData = async () => {
        console.log(this.state.employeeId);

        if (this.state.employeeId) {
            const employeeData = await this.FetchAPI('Employee/' + this.state.employeeId);

            //console.log(employeeData);

            const schedules = [... new Set(employeeData.employeeAvailabilitySchedules.map(item => item.timeframe))];            

            const filtered = employeeData.employeeAvailability.filter(item => item.timeframe == schedules[0]);

            var test = employeeData.lastCovidTestDate ? (moment().diff(moment(employeeData.lastCovidTestDate), 'days') <= 3) : false;

            // console.log('Time', test);
            employeeData.isTransportReady = test;

            await this.setState(
                {
                    schedules: schedules,
                    selectedSchedule: schedules[0],
                    employeeData: employeeData,
                    employeeSchedule: filtered,
                    active: employeeData.active,
                    // ABG: Required to deep copy here
                    jobProfiles: JSON.parse(JSON.stringify(employeeData.jobProfiles)),
                    email: employeeData.email,
                });
        }
        else {
            const defaultContractId = 20;
            const defaultEmployeeRole = 'INTERNAL';
            const defaultEmployeeType = 'Regular';

            // Creating new employee
            console.log('Creating new employee');

            var employeeData = {
                contractId: defaultContractId,
                jobProfile: defaultEmployeeRole,
                employeeType: defaultEmployeeType,
                isInternal: true,
                active: true,
                workdayActive: false,
                isOnLeave: false,
                jobProfiles: [],
                supervisorCodes: []
            };

            await this.setState({
                employeeData: employeeData,
                active: true,
                jobProfile: defaultEmployeeRole,
            });
        }
    }

    SaveEmployee = async (e) => {
        console.log('Saving...', e);

        var result = this.mainForm.instance.validate();

        if (result.isValid) {

            // Check on the job profiles being valid
            // Blank schedulingOfficeId if all TS job profiles are removed
            // We can only have one active internal job profile at a time
            // AND we do not want them to overlap
            if (this.state.employeeData.jobProfiles && this.state.employeeData.jobProfiles.length > 0) {
                var internalJobProfiles = this.state.employeeData.jobProfiles.filter((item) => item.isInternal).sort((a, b) => moment(a.effectiveBeginDate).valueOf() - moment(b.effectiveBeginDate).valueOf());

                for (var i = 1; i < internalJobProfiles.length; i++) {
                    var previousItem = internalJobProfiles[i - 1];
                    if (previousItem.effectiveEndDate == null && (i + 1) != internalJobProfiles.length) {
                        // Throw error that it has to be ended
                        alert(`Internal Job Profile [${previousItem.jobProfile}] beginning ${moment(previousItem.effectiveBeginDate).format('MM/DD/yyyy')} must contain an Effective End Date before a new Internal Job Profile can be registered.`);
                        return;
                    }
                    else {
                        // Make sure they do not conflict
                        var currentItem = internalJobProfiles[i];
                        var previousItemEndDate = moment(previousItem.effectiveEndDate);
                        var currentItemBeginDate = moment(currentItem.effectiveBeginDate);

                        console.log(previousItemEndDate);
                        console.log(currentItemBeginDate);
                        if (previousItemEndDate.isAfter(currentItemBeginDate)) {
                            alert(`Internal Job Profiles [${previousItem.jobProfile}] beginning ${moment(previousItem.effectiveBeginDate).format('MM/DD/yyyy')} and [${currentItem.jobProfile}] beginning ${moment(currentItem.effectiveBeginDate).format('MM/DD/yyyy')} have conflicting Effective Dates.]`);
                            return;
                        }
                    }
                }
            }
            else {
                alert('At least one Job Profile is required');
                return;
            }

            if (!this.state.employeeData.supervisorCodes || this.state.employeeData.supervisorCodes.length == 0) {
                alert('At least one Supervisory Org is required');
                return;
            }

            await this.setState({
                loading: true
            });

            //console.log(this.state.employeeData);
            try {
                const result = await this.PostAPI('Employee', this.state.employeeData);

                if (result.status == 1 && (this.state.active != this.state.employeeData.active || JSON.stringify(this.state.jobProfiles) != JSON.stringify(this.state.employeeData.jobProfiles) || this.state.email != this.state.employeeData.email)) {
                    //console.log(result);
                    await this.setState({
                        message: 'Performing Access Audit for Employee ' + result.employeeId
                    });

                    var result2 = await this.PostAPI('WorkdayIntegration/AuditAccess/' + result.employeeId);

                    //console.log(result2);

                    if (result2.status != 1) {
                        await alert(result2.message);
                    }
                }

                if (result.status == 1) {
                    this.props.navigate('/edit-employee/' + result.employeeId);
                    this.props.navigate(0);
                }
                else if (result.status == -1) {
                    await alert(result.message);
                }
                else {
                    alert('Failed. Please try again later.');
                }
            }
            finally {
                await this.setState({
                    loading: false
                });
            }
        }
    }

    unavailabilityDataStore = new CustomStore({
        key: 'id',
        load: (loadOptions) => {
            //console.log('Load', loadOptions);

            return FetchAPIPromise('EmployeeLeaveRequests/GetRequestsByEmployeeId?employeeId=' + this.state.employeeId);
        }
    });

    historyDataStore = new CustomStore({
        key: 'id',
        load: (loadOptions) => {
            //console.log('Load', loadOptions);

            return FetchAPIPromise('Employee/GetShiftHistoryByNumber?employeeId=' + this.state.employeeId);
        }
    });

    auditIndividualAccess = async (e) => {

        var dialogResult = await confirm('Are you sure you want to scan Employee data for access?');

        if (dialogResult) {
            try {
                this.setState({
                    loading: true
                });

                var result = await this.PostAPI('WorkdayIntegration/AuditAccess/' + this.state.employeeId);

                //console.log(result);

                if (result.status == -1) {
                    alert(result.message);

                    //this.setState({
                    //    isViewingImportStatus: true,
                    //    importStatus: {
                    //        isSuccess: false,
                    //        message: result.message
                    //    }
                    //});
                }
                else {
                    //this.setState({
                    //    isViewingImportStatus: true,
                    //    importStatus: {
                    //        isSuccess: true,
                    //        message: result.message,
                    //        importedUsers: result.importedUserCount,
                    //        updatedUsers: result.updatedUserCount,
                    //        importErrors: result.importErrors
                    //    }
                    //});

                    //console.log(this.state.importStatus);
                }
            }
            finally {
                await this.GetData();
                this.setState({
                    loading: false
                });
            }
        }
    }

    rateHistoryDataStore = new CustomStore({
        key: 'id',
        load: (loadOptions) => {
            //console.log('Load', loadOptions);

            return FetchAPIPromise('EmployeeRateHistory/GetHistoryByEmployeeId?employeeId=' + this.state.employeeId);
        }
    });

    scheduleChange = async (e) => {
        const filter = this.state.employeeData.employeeAvailability.filter(item => item.timeframe == e.value);

        await this.setState({
            employeeSchedule: filter,
            selectedSchedule: e.value
        })
    }

    onFieldDataChanged = async (e) => {
        //console.log('Field data changed...', e);

        switch (e.dataField) {

            case "lastCovidTestDate":
                {
                    var test = e.value ? (moment().diff(moment(e.value), 'days') <= 3) : false;

                    e.component.updateData('isTransportReady', test);

                    break;
                }
        }
    }

    hideChangeHistory = async () => {
        await this.setState({
            changeHistoryVisible: false
        });
    }

    showChangeHistory = async (e) => {
        await this.setState({
            changeHistoryVisible: true
        });

        this.changeHistoryDataGrid.instance.refresh();
    }

    checkRowEditability = (e) => {
        //console.log(e.row.data);

        return e.row.data.isInternal && !this.IsReadOnly();
    }

    changeLogDataSource = new CustomStore({
        key: 'id',
        load: (loadOptions) => {
            //console.log('Load', loadOptions);

            if (this.state.employeeId) {
                return FetchAPIPromise('Employee/GetChangeHistory?employeeId=' + this.state.employeeId);
            }
        }
    });

    onInitNewRow(e) {
        e.data.isInternal = true;
        e.data.workdayActive = false;
    }

    onInitNewCompetencyRow(e) {
        e.data.isInternal = true;
    }

    repaintForm = async (e) => {
        //console.log('Form repainted');

        // Check if there still exist any internal job profiles for TSs
        if (this.state.employeeData.jobProfiles && this.state.employeeData.jobProfiles.find((item) => item.jobProfile == 'TRASPCN') == undefined) {
            console.log('No more TS job profiles left');
            await this.updateProperty('employeeData', 'schedulingOfficeId', null);
        }

        this.forceUpdate();
    }

    render() {
        const isInternalEmployee = this.state.employeeData.isInternal && !this.IsReadOnly();
        const isTransportSpecialist = this.state.employeeData.jobProfiles && this.state.employeeData.jobProfiles.find((item) => item.jobProfile == 'TRASPCN') != undefined;
        const transportSpecialistRoleIsInternal = this.state.employeeData.jobProfiles && this.state.employeeData.jobProfiles.find((item) => item.jobProfile == 'TRASPCN' && item.isInternal) != undefined;
        var userRoles = msalAuth.getActiveAccount().idTokenClaims.roles;

        const isOnLeave = this.state.employeeData.isOnLeave;
        const canReactivate = !this.state.employeeData.isOnLeave && this.state.employeeData.employeeType != 'PTOC';

        var availabilityCategory = [
            {
                text: 'Open',
                value: 'OPEN'
            },
            {
                text: 'Limited',
                value: 'LIMITED'
            },
            {
                text: 'Seasonal',
                value: 'SEASONAL'
            }
        ];

        var schedule = [
            {
                text: 'Schedule A',
                value: 'SCHEDULEA'
            },
            {
                text: 'Schedule B',
                value: 'SCHEDULEB'
            }
        ];

        //console.log(isOnLeave);
        //console.log(this.state.employeeData);
        //console.log(isTransportSpecialist);

        const internalEmployeeProfiles = ['INTERNAL', 'TRASPCN', 'HR'];
        const employeeTypes = ['Regular', 'PTOC', 'Intern', 'Trainee'];

        return (
            <div className="container">
                <h1>{this.state.employeeId ? 'Edit' : 'Add Internal'} Employee</h1>
                <Form ref={ref => this.mainForm = ref}
                    id="form"
                    colCount={1}
                    formData={this.state.employeeData} onFieldDataChanged={this.onFieldDataChanged}
                    readOnly={this.IsReadOnly()}>
                    <Item itemType="group" caption="Basic Information" colCount={3}>
                        <Item dataField="firstName" editorOptions={{ readOnly: !isInternalEmployee }}>
                            <RequiredRule />
                            <Label text="First Name" />
                        </Item>
                        <Item dataField="middleName" editorOptions={{ readOnly: !isInternalEmployee }}>
                            <Label text="Middle Name" />
                        </Item>
                        <Item dataField="lastName" editorOptions={{ readOnly: !isInternalEmployee }}>
                            <RequiredRule />
                            <Label text="Last Name" />
                        </Item>

                        <Item dataField="employeeNo" editorOptions={{ readOnly: !isInternalEmployee }}>
                            <RequiredRule />
                            <Label text="Employee No" />
                        </Item>
                        <Item dataField="sex" editorType="dxSelectBox" editorOptions={{ searchEnabled: true, readOnly: !isInternalEmployee, dataSource: this.state.genders, displayExpr: 'name', valueExpr: 'name' }}>
                            <RequiredRule />
                            <Label text="Gender" />
                        </Item>
                        <Item dataField="birthDate" editorType="dxDateBox" editorOptions={{ readOnly: !isInternalEmployee }}>
                            <RequiredRule />
                            <Label text="Birth Date" />
                        </Item>

                        <Item dataField="officeId" editorType="dxSelectBox" editorOptions={{ searchEnabled: true, readOnly: !isInternalEmployee, dataSource: this.state.offices, displayExpr: 'name', valueExpr: 'id' }}>
                            <RequiredRule />
                            <Label text="Office" />
                        </Item>

                        <Item dataField="schedulingOfficeId" editorType="dxSelectBox" visible={isTransportSpecialist} editorOptions={{ searchEnabled: true, showClearButton: true, readOnly: !transportSpecialistRoleIsInternal, dataSource: this.state.offices, displayExpr: 'name', valueExpr: 'id' }}>
                            {isTransportSpecialist && <RequiredRule /> }
                            <Label text="Scheduling Office" />
                        </Item>

                        <Item dataField="email" editorOptions={{ readOnly: !isInternalEmployee }}>
                            <RequiredRule />
                            <Label text="Email" />
                        </Item>
                        <Item dataField="phone" editorOptions={{ readOnly: !isInternalEmployee, mask: '(000) 000-0000' }}>
                            <Label text="Phone" />
                        </Item>

                        <Item dataField="hireDate" editorType="dxDateBox" editorOptions={{ readOnly: !isInternalEmployee }}>
                            <Label text="Hire Date" />
                        </Item>
                        <Item dataField="timeTypeCode" editorType="dxSelectBox" editorOptions={{
                            searchEnabled: true, readOnly: !isInternalEmployee, dataSource: this.state.timeTypes, displayExpr: 'name', valueExpr: 'code'
                        }}>
                            <RequiredRule />
                            <Label text="Time Type" />
                        </Item>
                        <Item dataField="categoryCode" editorType="dxSelectBox" editorOptions={{
                            searchEnabled: true, readOnly: !isInternalEmployee, dataSource: this.state.categories, displayExpr: 'name', valueExpr: 'code'
                        }}>
                            <RequiredRule />
                            <Label text="Employee Category" />
                        </Item>

                        <Item dataField="employeeType" editorType="dxSelectBox" editorOptions={{
                            searchEnabled: true, readOnly: !isInternalEmployee, dataSource: employeeTypes
                        }}>
                            <RequiredRule />
                            <Label text="Employee Type" />
                        </Item>

                        <EmptyItem />
                        <Item dataField="active" editorType="dxCheckBox" editorOptions={{ readOnly: !canReactivate }}>
                            <Label text="Active" />
                        </Item>
                        <Item dataField="workdayActive" editorType="dxCheckBox" editorOptions={{ readOnly: true }}>
                            <Label text="Workday Active" />
                        </Item>

                        <Item dataField="isOnLeave" editorType="dxCheckBox" editorOptions={{ readOnly: true }} visible={isOnLeave}>
                            <Label text="On Leave" />
                        </Item>

                        <Item dataField="onLeaveTypeName" editorOptions={{ readOnly: true }} visible={isOnLeave}>
                            <Label  text="Leave Type" />
                        </Item>

                        <Item colSpan={3} dataField="notes" editorType="dxTextArea" />

                        <Item colSpan={3} visible={this.state.employeeId != undefined} itemType="button" buttonOptions={{ text: 'Change History', onClick: this.showChangeHistory }} />
                    </Item>

                    <TabbedItem colSpan={3}>
                        <Tab title="Profile">
                            <Item>
                                <Label text="Job Profiles" />
                                <DataGrid dataSource={this.state.employeeData.jobProfiles} showBorders={true} allowColumnResizing={true} onInitNewRow={this.onInitNewRow}
                                    onRowInserted={this.repaintForm} onRowUpdated={this.repaintForm} onRowRemoved={this.repaintForm}>
                                    <Editing mode="popup" allowAdding={!this.IsReadOnly()} allowUpdating={this.checkRowEditability} allowDeleting={this.checkRowEditability} />
                                    <FilterRow visible={true} />
                                    <Paging defaultPageSize={10} />
                                    <Pager showPageSizeSelector={true}
                                        allowedPageSizes={[5, 10, 20]}
                                        showInfo={true} />

                                    <Column caption="Job Profile" dataField="jobProfile">
                                        <RequiredRule />
                                        <Lookup allowClearing={true} dataSource={internalEmployeeProfiles} />
                                    </Column>
                                    <Column caption="Effective Begin Date" dataField="effectiveBeginDate" dataType="date" format="MM/dd/yyyy">
                                        <RequiredRule />
                                    </Column>
                                    <Column caption="Effective End Date" dataField="effectiveEndDate" dataType="date" format="MM/dd/yyyy" />
                                    <Column caption="Internal" dataField="isInternal">
                                        <FormItem visible={false} />
                                    </Column>
                                    <Column caption="Workday Active" dataField="workdayActive">
                                        <FormItem visible={false} />
                                    </Column>
                                </DataGrid>
                            </Item>
                            <Item dataField="passCode">
                                <Label text="Pass Code" />
                            </Item>
                            <Item>
                                <Label text="Supervisory Orgs" />
                                <DataGrid dataSource={this.state.employeeData.supervisorCodes} showBorders={true} allowColumnResizing={true}>
                                    <Editing mode="popup" allowAdding={isInternalEmployee} allowUpdating={isInternalEmployee} allowDeleting={isInternalEmployee} />
                                    <FilterRow visible={true} />
                                    <Paging defaultPageSize={10} />
                                    <Pager showPageSizeSelector={true}
                                        allowedPageSizes={[5, 10, 20]}
                                        showInfo={true} />

                                    <Column caption="Org Code" dataField="orgCode">
                                        <RequiredRule />
                                        <Lookup allowClearing={true} dataSource={this.state.supervisors} displayExpr='orgName' valueExpr = 'orgCode' />
                                    </Column>
                                    <Column caption="Effective Date" dataField="effectiveDate" dataType="date" format="MM/dd/yyyy">
                                        <RequiredRule />
                                    </Column>
                                </DataGrid>
                            </Item>
                        </Tab>

                        {this.state.employeeId &&
                            <Tab title="Competencies">
                                <DataGrid dataSource={this.state.employeeData.competencyList} showBorders={true} allowColumnResizing={true} onInitNewRow={this.onInitNewCompetencyRow}>
                                    <Editing mode="popup" allowAdding={!this.IsReadOnly()} allowUpdating={this.checkRowEditability} allowDeleting={this.checkRowEditability} />
                                    <FilterRow visible={true} />
                                    <Paging defaultPageSize={5} />
                                    <Pager showPageSizeSelector={false}
                                        showInfo={true} />

                                    <Column caption="Competency" dataField="tagCode">
                                        <RequiredRule />
                                        <Lookup allowClearing={true} dataSource={this.state.employeeTags} displayExpr='tagName' valueExpr='tagCode' />
                                    </Column>
                                    <Column caption="Expiration Date" dataField="expirationDate" dataType="date" format="MM/dd/yyyy">
                                    </Column>
                                    <Column caption="Internal" dataField="isInternal">
                                        <FormItem visible={false} />
                                    </Column>
                                </DataGrid>
                            </Tab>
                        }

                        {this.state.employeeId &&
                            <Tab title="Azure">
                                <Item dataField="adRegistrationAccessStatus" editorOptions={{ readOnly: true }}>
                                    <Label text="Azure Access Issues" />
                                </Item>

                                <Item dataField="lastADUserRegistrationCheckDateTime" editorType="dxDateBox" editorOptions={{ displayFormat: 'MM/dd/yyyy HH:mm', type: 'datetime', readOnly: true }}>
                                    <Label text="Status Checked" />
                                </Item>

                                <ButtonItem disabled={this.IsReadOnly()} buttonOptions={{ text: 'Refresh Azure Access Status', onClick: this.auditIndividualAccess }} />
                            </Tab>
                        }

                        {this.state.employeeId && isTransportSpecialist && (userRoles.find(element => element === 'Payroll') || userRoles.find(element => element === 'ProgramManager') || userRoles.find(element => element === 'SystemAdmin') || userRoles.find(element => element === 'ProgramManager_ReadOnly')) &&
                            <Tab title="HR">
                                <Item>
                                <DataGrid dataSource={this.rateHistoryDataStore}
                                    showBorders={true} allowColumnResizing={true}>
                                        <Column caption="Rate" dataField="rate" />
                                        <Column caption="Effective Date" dataField="changeDate" dataType="datetime" format="MM/dd/yy" />
                                    </DataGrid>
                                </Item>
                            </Tab>
                        }

                        {this.state.employeeId && isTransportSpecialist &&
                            <Tab title="Availability" colCount={2}>
                                <Item>
                                    <SelectBox readOnly={this.IsReadOnly()} items={this.state.schedules} defaultValue={this.state.selectedSchedule} onValueChanged={this.scheduleChange} />
                                </Item>

                                <Item dataField="availabilityCategory" editorType="dxRadioGroup" editorOptions={{ dataSource: availabilityCategory, displayExpr: 'text', valueExpr: 'value' }}>
                                    <Label text="Category" />
                                </Item>

                                <Item>
                                    <TreeView id="simple-treeview"
                                        items={this.state.employeeSchedule} showCheckBoxesMode={(this.IsReadOnly() ? 'none' : 'selectAll')} selectionMode="multiple" selectNodesRecursive={true}
                                        displayExpr="slot"
                                        selectedExpr="isSelected"
                                        expandedExpr="isExpanded"
                                        height={500} />
                                </Item>

                                <Item dataField="scheduleClass" editorType="dxRadioGroup" editorOptions={{ dataSource: schedule, displayExpr: 'text', valueExpr: 'value' }}>
                                    <Label text="Schedule" />
                                </Item>
                            </Tab>
                        }

                        {this.state.employeeId && isTransportSpecialist &&
                            <Tab title="Unavailability">
                                <DataGrid dataSource={this.unavailabilityDataStore}
                                    showBorders={true} allowColumnResizing={true}
                                    ref={ref => this.dataGrid = ref}>
                                    <FilterRow visible={true} />
                                    <Paging defaultPageSize={10} />
                                    <Pager showPageSizeSelector={true}
                                        allowedPageSizes={[5, 10, 20]}
                                        showInfo={true} />

                                    <Column caption="Description" dataField="name" />
                                    <Column caption="Start Time" dataField="disabledStartTime" dataType="datetime" format="MM/dd/yy, HH:mm" />
                                    <Column caption="End Time" dataField="disabledEndTime" dataType="datetime" format="MM/dd/yy, HH:mm" />
                                </DataGrid>
                            </Tab>
                        }
                        {this.state.employeeId && isTransportSpecialist &&
                            <Tab title="Trip History">
                                <DataGrid dataSource={this.historyDataStore}
                                    showBorders={true} allowColumnResizing={true}
                                    ref={ref => this.dataGrid = ref}>
                                    <FilterRow visible={true} />
                                    <Paging defaultPageSize={10} />
                                    <Pager showPageSizeSelector={true}
                                        allowedPageSizes={[5, 10, 20]}
                                        showInfo={true} />
                                    <Column caption="Work Order Number" dataField="workOrderNumber" dataType="string" />
                                    <Column caption="Work Order Name" dataField="workOrderName" cellRender={function (options) { return (<Link to={`/workorder-detail/${options.row.data.id}`}>{options.value}</Link>); }} />
                                    <Column caption="Start Date" dataField="beginTripDate" dataType="datetime" format="MM/dd/yy, HH:mm" defaultSortIndex={0} defaultSortOrder="desc" />
                                    <Column caption="End Date" dataField="endTripDate" dataType="datetime" format="MM/dd/yy, HH:mm" />
                                    <Column caption="Status" dataField="workOrderStatus" dataType="string" />
                                </DataGrid>
                            </Tab>
                        }
                    </TabbedItem>

                    <ButtonItem disabled={this.IsReadOnly()} buttonOptions={{ text: 'Save', onClick: this.SaveEmployee }} />
                </Form>

                <Popup visible={this.state.changeHistoryVisible} onHiding={this.hideChangeHistory} dragEnabled={false}
                    closeOnOutsideClick={true} showTitle={true} title="Change History" width={900} height={500}>

                    <DataGrid dataSource={this.changeLogDataSource} showBorders={true} allowColumnResizing={true}
                        ref={ref => this.changeHistoryDataGrid = ref}>
                        <FilterRow visible={true} />
                        <Paging defaultPageSize={5} />
                        <Pager showPageSizeSelector={true}
                            allowedPageSizes={[5, 10, 20]}
                            showInfo={true} visible={true} />

                        <Column dataField="updateType" />
                        <Column dataField="updateField" caption="Field" />
                        <Column dataField="oldValue" />
                        <Column dataField="newValue" />
                        <Column dataField="display" caption="Change User" />
                        <Column dataField="createDate" caption="Change Date" dataType="datetime" format="MM/dd/yyyy HH:mm" />
                    </DataGrid>
                </Popup>

                <LoadPanel
                    visible={this.state.loading}
                    message={this.state.message} />
                <br />
            </div>
        );
    }
}

export default withParams(EmployeeEditContainer);