import React from 'react';
import PropTypes from 'prop-types';
import CustomStore from 'devextreme/data/custom_store';
import BaseComponent, { FetchAPIPromise, PostAPIPromise } from '../BaseComponent.js';
import DataGrid, { Column, GroupPanel, MasterDetail, Button as GridButton, Editing, Export, FilterRow, Selection } from 'devextreme-react/data-grid';
import { msalAuth } from '../../msal/MsalAuthProvider';
import Popup from 'devextreme-react/popup';
import { ScrollView } from 'devextreme-react/scroll-view';
import Button from 'devextreme-react/button';
import Form, { Item, Label, RequiredRule } from 'devextreme-react/form';

export class WorkOrderEmployeeHotelStaysContainer extends BaseComponent {
    static propTypes = {
        workOrder: PropTypes.object
    };

    constructor(props) {
        super(props);
        this.workOrderName = props.workOrder.workOrderName;
        this.state = {
            userIsSysAdmin: false,

            isCorrectingFlight: false,
            eligibleFlightEmployees: [],

            isMovingSupportFlight: false,
            eligibleFlightSupportStaff: [],

            isMovingEmployeeHotelStay: false,
            eligibleHotelStayEmployees: [],

            isMergingEmployeeHotelStay: false,
            overwritingMergeItem: [],
            eligibleMergeHotels: [],

            isMovingWorkOrder: false,
            destinationWorkOrder: {
                workOrderNumber: ''
            },

            employeeList: [],

            isCorrectingSupportHotel: false,

            isAddingHotelReservation: false,
            hotelReservationDetails: {}
        };
    }

    GetData = async () => {
        var userRoles = msalAuth.getActiveAccount().idTokenClaims.roles;

        var isUserSystemAdmin = userRoles.findIndex(element => element === 'SystemAdmin') > -1;
        //console.log('User ' + (isUserSystemAdmin ? 'IS' : 'IS NOT') + ' a Sys Admin');

        await this.setState({
            userIsSysAdmin: isUserSystemAdmin
        });
    }

    GetDropDownData = async () => {
        var employeeListData = await this.FetchAPI('WorkOrderResourcesEmployeeList/' + this.props.workOrder.id);

        await this.setState({
            employeeList: employeeListData
        });
    }

    hotelStaysStore = new CustomStore({
        key: 'employeeItineraryId',
        load: (loadOptions) => {
            //console.log('Load', loadOptions);
            //console.log(this.props.workOrder.id);

            if (this.props.workOrder.id) {
                return FetchAPIPromise('WorkOrderEmployeeHotelStays/' + this.props.workOrder.id);
            }
        },
        update: (key, values) => {
            if (values.billableUpdate) {
                return PostAPIPromise('WorkOrderEmployeeHotelStays/SetBillableStatus?itineraryId=' + key);
            }
            else {
                return PostAPIPromise('WorkOrderEmployeeHotelStays?itineraryId=' + key);
            }
        }
    });

    setHotelStayStatus = async (e) => {
        //console.log(e);

        await this.hotelStaysStore.update(e.row.data.employeeItineraryId, { billableUpdate: false });

        this.dataGrid.instance.refresh();
    }

    setBillableStatus = async (e) => {
        console.log(e);

        await this.hotelStaysStore.update(e.row.data.id, { billableUpdate: true});

        this.dataGrid.instance.refresh();
    }

    componentIsReadOnly = () => {
        return this.IsReadOnly() || this.props.workOrder.statusID > 1;
    }

    isBillable = (options) => {
        return options.row.data && options.row.data.isBillable == true;
    }

    notIsBillable = (options) => {
        return options.row.data && options.row.data.isBillable != true;
    }

    IsActive = (options) => {
        console.log('IsActive', options);
        return options.row.data && options.row.data.itineraryStatus != 'CANCELLED';
    }

    IsCancelled = (options) => {
        console.log('IsCancelled', options);
        return options.row.data && options.row.data.itineraryStatus != 'ACTIVE';
    }

    onRowPrepared = (e) => {
        if (e.rowType == 'data' && e.data.itineraryStatus == 'CANCELLED') {
            e.rowElement.className = e.rowElement.className + ' cancelledRow';
        }
    }

    isCorrectVisible = (options) => {
        //console.log(options.row.data);
        return options.row.data && !options.row.data.employeeID && this.state.userIsSysAdmin;
    }

    isUnlinkHotelStayVisible = (options) => {
        //console.log(options.row.data);
        return options.row.data && options.row.data.employeeID && this.state.userIsSysAdmin;
    }

    isSupporUnlinkHotelStayVisible = (options) => {
        //console.log(options.row.data);
        return options.row.data && options.row.data.travelerID && this.state.userIsSysAdmin;
    }

    isSupportCorrectVisible = (options) => {
        console.log(options.row.data);
        return options.row.data && !options.row.data.travelerID && this.state.userIsSysAdmin;
    }

    correctHotelStay = async (e) => {
        // console.log(e);

        const employeesData = await this.FetchAPI('WorkOrderResourcesEmployeeList/' + this.props.workOrder.id);
        const inactiveEmployeesData = await this.FetchAPI('WorkOrderResourcesEmployeeList/GetInactiveEmployees/' + this.props.workOrder.id);

        this.setState({
            isCorrectingHotel: true,
            itineraryItemId: e.row.data.employeeItineraryId,
            eligibleHotelEmployees: employeesData.concat(inactiveEmployeesData)
        });
    }

    unlinkHotelStay = async (e) => {
        // console.log(e);

        var result = await this.PostAPI('WorkOrderEmployeeHotelStays/UnlinkHotelStay?itineraryId=' + e.row.data.employeeItineraryId);

        if (result.status == -1) {
            alert(result.message);
        }
        else {
            this.dataGrid.instance.refresh();
            alert('Hotel Stay unlink Successful.');
        }
    }

    unlinkSupportHotelStay = async (e) => {
        // console.log(e);

        var result = await this.PostAPI('WorkOrderSupportStaffHotelStays/UnlinkHotelStay?itineraryId=' + e.row.data.supportStaffItineraryId);

        if (result.status == -1) {
            alert(result.message);
        }
        else {
            this.supportStaffHotelStaysDataGrid.instance.refresh();
            alert('Hotel Stay unlink Successful.');
        }
    }

    correctSupportHotelStay = async (e) => {
        // console.log(e);

        const employeesData = await this.FetchAPI('WorkOrderResourcesSupportStaffList/' + this.props.workOrder.id);
        const inactiveEmployeesData = await this.FetchAPI('WorkOrderResourcesSupportStaffList/GetInactiveEmployees/' + this.props.workOrder.id);

        this.setState({
            isCorrectingSupportHotel: true,
            itineraryItemId: e.row.data.supportStaffItineraryId,
            eligibleHotelEmployees: employeesData.concat(inactiveEmployeesData)
        });
    }

    hideCorrectDialog = () => {
        this.setState({
            isCorrectingHotel: false,
            itineraryItemId: null,
            eligibleHotelEmployees: []
        });
    }

    hideSupportCorrectDialog = () => {
        this.setState({
            isCorrectingSupportHotel: false,
            itineraryItemId: null,
            eligibleHotelEmployees: []
        });
    }

    saveEmployeeHotelMerge = async () => {
        this.mergeHotelsDataGrid.instance.getSelectedRowsData().then(async (rowData) => {
            if (rowData.length == 0) {
                alert("Please select a Hotel Stay to Merge Hotel Stay to.");
            }
            else {
                var rowToMerge = rowData[0];

                //console.log(rowToMove);

                var param = {
                    sourceItineraryItemId: this.state.overwritingMergeItem[0].employeeItineraryId,
                    destinationItineraryItemId: rowToMerge.employeeItineraryId
                }

                var moveResult = await this.PostAPI('WorkOrderEmployeeHotelStays/MergeEmployeeHotelStay', param);

                if (moveResult.status == -1) {
                    alert(moveResult.message);
                }
                else {
                    this.hideMergeDialog();
                    this.dataGrid.instance.refresh();
                    this.supportStaffHotelStaysDataGrid.instance.refresh();
                }
            }
        })
    }

    saveHotelCorrection = async () => {
        this.correctHotelsDataGrid.instance.getSelectedRowsData().then(async (rowData) => {
            if (rowData.length == 0) {
                alert("Please select an Employee to correct Hotel to.");
            }
            else {
                var rowToMove = rowData[0];

                //console.log(rowToMove);

                var param = {
                    employeeId: rowToMove.employeeId,
                    itineraryItemId: this.state.itineraryItemId
                }

                var moveResult = await this.PostAPI('WorkOrderEmployeeHotelStays/CorrectEmployeeHotel', param);

                //console.log(moveResult);

                if (moveResult.status == -1) {
                    alert(moveResult.message);
                }
                else {
                    this.hideCorrectDialog();
                    this.dataGrid.instance.refresh();
                    alert('Hotel correction Successful.');
                }
            }
        })
    }

    saveSupportHotelCorrection = async () => {
        this.correctSupportHotelsDataGrid.instance.getSelectedRowsData().then(async (rowData) => {
            if (rowData.length == 0) {
                alert("Please select a Support Staff to correct Hotel to.");
            }
            else {
                var rowToMove = rowData[0];

                //console.log(rowToMove);

                var param = {
                    travelerID: rowToMove.id,
                    itineraryItemId: this.state.itineraryItemId
                }

                var moveResult = await this.PostAPI('WorkOrderSupportStaffHotelStays/CorrectSupportStaffHotel', param);

                //console.log(moveResult);

                if (moveResult.status == -1) {
                    alert(moveResult.message);
                }
                else {
                    this.hideSupportCorrectDialog();
                    this.supportStaffHotelStaysDataGrid.instance.refresh();
                    alert('Hotel correction Successful.');
                }
            }
        })
    }

    moveWorkOrder = async (e) => {
        //console.log(e);

        this.setState({
            isMovingWorkOrder: true,
            itineraryItemId: e.row.data.employeeItineraryId
        });
    }

    moveSupportHotelStay = async (e) => {
        const employeesData = await this.FetchAPI('WorkOrderResourcesSupportStaffList/' + this.props.workOrder.id);

        //console.log(employeesData);

        this.setState({
            isMovingSupportHotelStay: true,
            itineraryItemId: e.row.data.employeeItineraryId,
            eligibleHotelStaySupportStaff: employeesData
        });
    }

    mergeHotelStay = async (e) => {
        const hotelData = await this.FetchAPI('WorkOrderEmployeeHotelStays/' + this.props.workOrder.id);

        //console.log(employeesData);

        this.setState({
            isMergingEmployeeHotelStay: true,
            overwritingMergeItem: [e.row.data],
            eligibleMergeHotels: hotelData
        });
    }

    moveEmployeeHotelStay = async (e) => {
        const employeesData = await this.FetchAPI('WorkOrderResourcesEmployeeList/' + this.props.workOrder.id);
        const inactiveEmployeesData = await this.FetchAPI('WorkOrderResourcesEmployeeList/GetInactiveEmployees/' + this.props.workOrder.id);

        //console.log(employeesData);

        this.setState({
            isMovingEmployeeHotelStay: true,
            itineraryItemId: e.row.data.supportStaffItineraryId,
            eligibleHotelStayEmployees: employeesData.concat(inactiveEmployeesData)
        });
    }

    isMoveHotelStayVisible = (options) => {
        return options.row.data && !options.row.data.employeeID && this.state.userIsSysAdmin;
    }

    isMoveEmployeeHotelStayVisible = (options) => {
        return options.row.data && !options.row.data.travelerID && this.state.userIsSysAdmin;
    }

    hideMoveWorkOrderDialog = () => {
        this.setState({
            isMovingWorkOrder: false,

            destinationWorkOrder: {
                workOrderNumber: ''
            }
        });
    }

    hideMoveSupportDialog = () => {
        this.setState({
            isMovingSupportHotelStay: false,
            itineraryItemId: null,
            eligibleHotelStaySupportStaff: []
        });
    }

    hideMoveEmployeeDialog = () => {
        this.setState({
            isMovingEmployeeHotelStay: false,
            itineraryItemId: null,
            eligibleHotelStayEmployees: []
        });
    }

    saveWorkOrderMove = async () => {

        var result = this.moveWorkOrderForm.instance.validate();

        if (result.isValid) {

            var param = {
                itineraryItemId: this.state.itineraryItemId,
                destinationWorkOrderNumber: this.state.destinationWorkOrder.workOrderNumber
            };

            var moveResult = await this.PostAPI('WorkOrderEmployeeHotelStays/MoveWorkOrderHotelStay', param);

            console.log(moveResult);

            if (moveResult.status == -1) {
                alert(moveResult.message);
            }
            else {
                this.hideMoveWorkOrderDialog();
                this.dataGrid.instance.refresh();
            }
        }
    }

    saveSupportHotelStayMove = async () => {
        this.moveSupportHotelStaysDataGrid.instance.getSelectedRowsData().then(async (rowData) => {
            if (rowData.length == 0) {
                alert("Please select a Support Staff to move Hotel Stay to.");
            }
            else {
                var rowToMove = rowData[0];

                //console.log(rowToMove);

                var param = {
                    travelerID: rowToMove.id,
                    itineraryItemId: this.state.itineraryItemId
                }

                var moveResult = await this.PostAPI('WorkOrderSupportStaffHotelStays/MoveSupportStaffHotelStay', param);

                if (moveResult.status == -1) {
                    alert(moveResult.message);
                }
                else {
                    this.hideMoveSupportDialog();
                    this.dataGrid.instance.refresh();
                    this.supportStaffHotelStaysDataGrid.instance.refresh();
                }
            }
        });
    }

    saveEmployeeHotelStayMove = async () => {
        this.moveEmployeeHotelStaysDataGrid.instance.getSelectedRowsData().then(async (rowData) => {
            if (rowData.length == 0) {
                alert("Please select an Employee to move Hotel Stay to.");
            }
            else {
                var rowToMove = rowData[0];

                //console.log(rowToMove);

                var param = {
                    employeeID: rowToMove.employeeId,
                    itineraryItemId: this.state.itineraryItemId
                }

                var moveResult = await this.PostAPI('WorkOrderEmployeeHotelStays/MoveEmployeeHotelStay', param);

                if (moveResult.status == -1) {
                    alert(moveResult.message);
                }
                else {
                    this.hideMoveEmployeeDialog();
                    this.dataGrid.instance.refresh();
                    this.supportStaffHotelStaysDataGrid.instance.refresh();
                }
            }
        });
    }

    supportHotelStaysStore = new CustomStore({
        key: 'supportStaffItineraryId',
        load: (loadOptions) => {
            //console.log('Load', loadOptions);
            //console.log(this.props.workOrder.id);

            if (this.props.workOrder.id) {
                return FetchAPIPromise('WorkOrderSupportStaffHotelStays/' + this.props.workOrder.id);
            }
        },
        update: (key, values) => {
            return PostAPIPromise('WorkOrderSupportStaffHotelStays?itineraryId=' + key);
        }
    });

    setSupportHotelStayStatus = async (e) => {
        //console.log(e);

        await this.supportHotelStaysStore.update(e.row.data.supportStaffItineraryId, {});

        this.supportStaffHotelStaysDataGrid.instance.refresh();
    }

    canActivate = (options) => {
        return options.row.data && options.row.data.itineraryStatus != 'ACTIVE' && options.row.data.status == 'Confirmed';
    }

    addHotelReservation = async (e) => {

        await this.setState({
            isAddingHotelReservation: true,
            hotelReservationDetails: {}
        });
    }

    hideMergeDialog = () => {
        this.setState({
            isMergingEmployeeHotelStay: false
            //hotelReservationDetails: {}
        });
    }

    hideHotelReservation = () => {
        this.setState({
            isAddingHotelReservation: false,
            hotelReservationDetails: {}
        });
    }

    saveHotelReservation = async () => {
        var result = this.editReservationFormControl.instance.validate();

        if (result.isValid) {

            var hotelDetails = this.state.hotelReservationDetails;

            console.log(hotelDetails);

            if (!hotelDetails.employeeList || hotelDetails.employeeList.length == 0) {
                alert('Hotel Reservation must contain at least one Employee');
            } else {

                hotelDetails.workOrderId = this.props.workOrder.id;

                const result = await this.PostAPI('FroschItinerary/AddHotelReservation', hotelDetails);

                console.log(result);

                if (result.status == 1) {
                    await this.setState({
                        isAddingHotelReservation: false,
                        hotelReservationDetails: {}
                    });

                    this.dataGrid.instance.refresh();
                }
                else if (result.status == -1) {
                    alert(result.message);
                }
                else {
                    //console.log(result);
                    alert('Failed. Please try again later.');
                }
            }
        }
    }

    render() {
        return (
            <div style={{ margin: "10px" }}>
                {this.props.workOrder != null && (this.state.userIsSysAdmin || this.state.userIsCCSupervisor) &&
                    <Button disabled={this.componentIsReadOnly()} icon="home" text="Add Hotel Reservation" onClick={this.addHotelReservation} />
                }
                <br />
                <br />
                <DataGrid ref={ref => this.dataGrid = ref} dataSource={this.hotelStaysStore} showBorders={true} allowColumnResizing={true} onRowPrepared={this.onRowPrepared} onExporting={this.onExporting}>
                    <Export enabled={true} fileName={"employeeHotelStays" + this.workOrderName} />
                    <Editing mode='row' allowUpdating={this.allowUpdating && !this.componentIsReadOnly()} />
                    <GroupPanel visible={true} />

                    <Column caption="Employee Name" dataField="travelerName" groupIndex={0} />
                    <Column caption="Status" dataField="status" />
                    <Column caption="Hotel Name" dataField="hotelName" />
                    <Column caption="Check In" dataField="checkIn" dataType="datetime" format="MM/dd/yy, HH:mm" />
                    <Column caption="Check Out" dataField="checkOut" dataType="datetime" format="MM/dd/yy, HH:mm" />
                    <Column caption="Booking Status" dataField="itineraryStatus" />
                    <Column caption="Billable?" dataField="isBillable" />

                    <Column visible={!this.componentIsReadOnly()} type="buttons">
                        <GridButton
                            text="Move as Support"
                            hint="Move as Support"
                            onClick={this.moveSupportHotelStay}
                            visible={this.isMoveHotelStayVisible}
                        />
                        <GridButton
                            text="Move Work Order"
                            hint="Move Work Order"
                            onClick={this.moveWorkOrder}
                            visible={this.isMoveHotelStayVisible}
                        />
                        <GridButton
                            text="Correct"
                            hint="Correct"
                            onClick={this.correctHotelStay}
                            visible={this.isCorrectVisible}
                        />
                        <GridButton
                            text="Merge"
                            hint="Merge"
                            onClick={this.mergeHotelStay}
                        />
                        <GridButton
                            text="Mark Not Billable"
                            hint="Mark Not Billable"
                            onClick={this.setBillableStatus}
                            visible={this.isBillable}
                        />
                        <GridButton
                            text="Mark Billable"
                            hint="Mark Billable"
                            onClick={this.setBillableStatus}
                            visible={this.notIsBillable}
                        />
                        <GridButton
                            text="Unlink"
                            hint="Unlink"
                            onClick={this.unlinkHotelStay}
                            visible={this.isUnlinkHotelStayVisible}
                        />
                        <GridButton
                            text="Cancel"
                            hint="Cancel"
                            onClick={this.setHotelStayStatus}
                            visible={this.IsActive}
                        />
                        <GridButton
                            text="Make Active"
                            hint="Make Active"
                            onClick={this.setHotelStayStatus}
                            visible={this.canActivate}
                        />
                    </Column>

                    <MasterDetail enabled={true} component={HotelStayDetail} />
                </DataGrid>
                <br />

                <header><b>Support Staff Hotel Stays</b></header>
                <br />

                <DataGrid ref={ref => this.supportStaffHotelStaysDataGrid = ref} dataSource={this.supportHotelStaysStore} showBorders={true} allowColumnResizing={true} onRowPrepared={this.onRowPrepared} onExporting={this.onExporting}>
                    <Export enabled={true} fileName={"supportStaffHotelStays" + this.workOrderName} />
                    <Editing mode="row" allowUpdating={true} />
                    <GroupPanel visible={true} />

                    <Column caption="Employee Name" dataField="travelerName" groupIndex={0} />
                    <Column caption="Status" dataField="status" />
                    <Column caption="Hotel Name" dataField="hotelName" />
                    <Column caption="Check In" dataField="checkIn" dataType="datetime" format="MM/dd/yy, HH:mm" />
                    <Column caption="Check Out" dataField="checkOut" dataType="datetime" format="MM/dd/yy, HH:mm" />
                    <Column caption="Booking Status" dataField="itineraryStatus" />

                    <Column visible={!this.componentIsReadOnly()} type="buttons">
                        <GridButton
                            text="Move as Employee"
                            hint="Move as Employee"
                            onClick={this.moveEmployeeHotelStay}
                            visible={this.isMoveEmployeeHotelStayVisible}
                        />
                        <GridButton
                            text="Correct"
                            hint="Correct"
                            onClick={this.correctSupportHotelStay}
                            visible={this.isSupportCorrectVisible}
                        />
                        <GridButton
                            text="Unlink"
                            hint="Unlink"
                            onClick={this.unlinkSupportHotelStay}
                            visible={this.isSupporUnlinkHotelStayVisible}
                        />
                        <GridButton
                            text="Cancel"
                            hint="Cancel"
                            onClick={this.setSupportHotelStayStatus}
                            visible={this.IsActive}
                        />
                        <GridButton
                            text="Make Active"
                            hint="Make Active"
                            onClick={this.setSupportHotelStayStatus}
                            visible={this.IsCancelled}
                        />
                    </Column>
                    <MasterDetail enabled={true} component={HotelStayDetail} />
                </DataGrid>

                <Popup visible={this.state.isCorrectingHotel} onHiding={this.hideCorrectDialog} dragEnabled={true}
                    closeOnOutsideClick={false} showTitle={false} width={800} height={475}>
                    <ScrollView height={375}>
                        <p>Select the Employee to correct this Hotel to</p>
                        <br />
                        <DataGrid ref={ref => this.correctHotelsDataGrid = ref} dataSource={this.state.eligibleHotelEmployees} showBorders={true} allowColumnResizing={true}>
                            <FilterRow visible={true} />
                            <Selection mode="single" selectAllMode="allPages" deferred={true} />

                            <Column caption="Employee No" dataField="employeeNo" />
                            <Column caption="First Name" dataField="firstName" />
                            <Column caption="Middle Name" dataField="middleName" />
                            <Column caption="Last Name" dataField="lastName" />
                            <Column caption="Gender" dataField="sex" />
                            <Column caption="Office" dataField="office" />
                        </DataGrid>
                    </ScrollView>
                    <br />

                    <div style={{ textAlign: 'center' }}>
                        <Button text="Save" onClick={this.saveHotelCorrection.bind(this)} />
                        &nbsp;&nbsp;
                        <Button text="Cancel" onClick={this.hideCorrectDialog} />
                    </div>
                </Popup>

                <Popup visible={this.state.isCorrectingSupportHotel} onHiding={this.hideSupportCorrectDialog} dragEnabled={true}
                    closeOnOutsideClick={false} showTitle={false} width={800} height={475}>
                    <ScrollView height={375}>
                        <p>Select the Support Staff to correct this Hotel to</p>
                        <br />
                        <DataGrid ref={ref => this.correctSupportHotelsDataGrid = ref} dataSource={this.state.eligibleHotelEmployees} showBorders={true} allowColumnResizing={true}>
                            <FilterRow visible={true} />
                            <Selection mode="single" selectAllMode="allPages" deferred={true} />

                            <Column caption="First Name" dataField="firstName" />
                            <Column caption="Middle Name" dataField="middleName" />
                            <Column caption="Last Name" dataField="lastName" />
                        </DataGrid>
                    </ScrollView>
                    <br />

                    <div style={{ textAlign: 'center' }}>
                        <Button text="Save" onClick={this.saveSupportHotelCorrection.bind(this)} />
                        &nbsp;&nbsp;
                        <Button text="Cancel" onClick={this.hideSupportCorrectDialog} />
                    </div>
                </Popup>

                <Popup visible={this.state.isMovingSupportHotelStay} onHiding={this.hideMoveSupportDialog} dragEnabled={true}
                    closeOnOutsideClick={false} showTitle={false} width={800} height={475}>
                    <ScrollView height={375}>
                        <p>Select the Support Staff to move this Hotel Stay to</p>
                        <br />
                        <DataGrid ref={ref => this.moveSupportHotelStaysDataGrid = ref} dataSource={this.state.eligibleHotelStaySupportStaff} showBorders={true} allowColumnResizing={true}>
                            <FilterRow visible={true} />
                            <Selection mode="single" selectAllMode="allPages" deferred={true} />

                            <Column caption="First Name" dataField="firstName" />
                            <Column caption="Middle Name" dataField="middleName" />
                            <Column caption="Last Name" dataField="lastName" />
                        </DataGrid>
                    </ScrollView>
                    <br />

                    <div style={{ textAlign: 'center' }}>
                        <Button text="Save" onClick={this.saveSupportHotelStayMove.bind(this)} />
                        &nbsp;&nbsp;
                        <Button text="Cancel" onClick={this.hideMoveSupportDialog} />
                    </div>
                </Popup>

                <Popup visible={this.state.isMovingEmployeeHotelStay} onHiding={this.hideMoveEmployeeDialog} dragEnabled={true}
                    closeOnOutsideClick={false} showTitle={false} width={800} height={475}>
                    <ScrollView height={375}>
                        <p>Select the Employee to move this Hotel Stay to</p>
                        <br />
                        <DataGrid ref={ref => this.moveEmployeeHotelStaysDataGrid = ref} dataSource={this.state.eligibleHotelStayEmployees} showBorders={true} allowColumnResizing={true}>
                            <FilterRow visible={true} />
                            <Selection mode="single" selectAllMode="allPages" deferred={true} />

                            <Column caption="Employee No" dataField="employeeNo" />
                            <Column caption="First Name" dataField="firstName" />
                            <Column caption="Middle Name" dataField="middleName" />
                            <Column caption="Last Name" dataField="lastName" />
                            <Column caption="Gender" dataField="sex" />
                            <Column caption="Office" dataField="office" />
                        </DataGrid>
                    </ScrollView>
                    <br />

                    <div style={{ textAlign: 'center' }}>
                        <Button text="Save" onClick={this.saveEmployeeHotelStayMove.bind(this)} />
                        &nbsp;&nbsp;
                        <Button text="Cancel" onClick={this.hideMoveEmployeeDialog} />
                    </div>
                </Popup>

                <Popup visible={this.state.isMovingWorkOrder} onHiding={this.hideMoveWorkOrderDialog} dragEnabled={true}
                    closeOnOutsideClick={false} showTitle={false} width={800} height={475}>
                    <ScrollView height={375}>
                        <p>Enter the Work Order to move this Hotel Stay to</p>
                        <br />
                        <Form ref={ref => this.moveWorkOrderForm = ref} formData={this.state.destinationWorkOrder}>
                            <Item editorType="dxTextBox" dataField="workOrderNumber">
                                <RequiredRule />
                            </Item>
                        </Form>
                    </ScrollView>
                    <br />

                    <div style={{ textAlign: 'center' }}>
                        <Button text="Save" onClick={this.saveWorkOrderMove.bind(this)} />
                        &nbsp;&nbsp;
                        <Button text="Cancel" onClick={this.hideMoveWorkOrderDialog} />
                    </div>
                </Popup>

                <Popup visible={this.state.isAddingHotelReservation} onHiding={this.hideHotelReservation} dragEnabled={true}
                    closeOnOutsideClick={false} showTitle={true} title="Add Hotel Reservation" width={800} height={495}>
                    <ScrollView height={360}>
                        <Form ref={ref => this.editReservationFormControl = ref}
                            id="form" formData={this.state.hotelReservationDetails} colCount={2}>

                            <Item editorType="dxTextBox" dataField="recordLocator" editorOptions={{ maxLength: 10 }}>
                                <Label text="Record Locator" />
                                <RequiredRule />
                            </Item>

                            <Item editorType="dxDateBox" dataField="reservationDate" editorOptions={{ type: 'datetime', displayFormat: "MM/dd/yyyy HH:mm" }}>
                                <Label text="Reservation Date" />
                                <RequiredRule />
                            </Item>

                            <Item editorType="dxTextBox" dataField="confirmationNumber" editorOptions={{ maxLength: 10 }}>
                                <Label text="Confirmation #" />
                                <RequiredRule />
                            </Item>

                            <Item editorType="dxTextBox" dataField="status" editorOptions={{ maxLength: 25 }}>
                                <Label text="Status" />
                                <RequiredRule />
                            </Item>

                            <Item colSpan={2} editorType="dxTextBox" dataField="hotelName" editorOptions={{ maxLength: 100 }}>
                                <Label text="Hotel Name" />
                                <RequiredRule />
                            </Item>

                            <Item colSpan={2} editorType="dxTextBox" dataField="hotelAddress" editorOptions={{ maxLength: 100 }}>
                                <Label text="Hotel Address" />
                                <RequiredRule />
                            </Item>

                            <Item editorType="dxTextBox" dataField="hotelPhone" editorOptions={{ maxLength: 20 }}>
                                <Label text="Hotel Phone" />
                                <RequiredRule />
                            </Item>

                            <Item editorType="dxTextBox" dataField="hotelFax" editorOptions={{ maxLength: 20 }}>
                                <Label text="Hotel Fax" />
                                <RequiredRule />
                            </Item>

                            <Item editorType="dxNumberBox" dataField="rooms">
                                <Label text="Rooms" />
                                <RequiredRule />
                            </Item>

                            <Item editorType="dxTextBox" dataField="roomType" editorOptions={{ maxLength: 100 }}>
                                <Label text="Room Type" />
                                <RequiredRule />
                            </Item>

                            <Item editorType="dxTextBox" dataField="rate" editorOptions={{ maxLength: 20 }}>
                                <Label text="Rate" />
                                <RequiredRule />
                            </Item>

                            <Item editorType="dxTextBox" dataField="stayDuration" editorOptions={{ maxLength: 20 }}>
                                <Label text="Stay Duration" />
                                <RequiredRule />
                            </Item>

                            <Item colSpan={2} editorType="dxTextBox" dataField="cancelationPolicy" editorOptions={{ maxLength: 100 }}>
                                <Label text="Cancelation Policy" />
                                <RequiredRule />
                            </Item>

                            <Item editorType="dxDateBox" dataField="checkInLocalTime" editorOptions={{ type: 'datetime', displayFormat: "MM/dd/yyyy HH:mm" }}>
                                <Label text="Check In" />
                                <RequiredRule />
                            </Item>

                            <Item editorType="dxDateBox" dataField="checkOutLocalTime" editorOptions={{ type: 'datetime', displayFormat: "MM/dd/yyyy HH:mm" }}>
                                <Label text="Check Out" />
                                <RequiredRule />
                            </Item>

                            <Item colSpan={2} editorType="dxTagBox" dataField="employeeList" editorOptions={{ applyValueMode: 'useButtons', showSelectionControls: true, dataSource: this.state.employeeList, displayExpr: 'displayValue', valueExpr: 'employeeId' }}>
                                <Label text="Employees" />
                            </Item>
                        </Form>
                    </ScrollView>
                    <br />

                    <div style={{ textAlign: 'center' }}>
                        <Button text="OK" onClick={this.saveHotelReservation} />
                        &nbsp;&nbsp;
                        <Button text="Cancel" onClick={this.hideHotelReservation} />
                    </div>
                </Popup>

                <Popup visible={this.state.isMergingEmployeeHotelStay} onHiding={this.hideMergeDialog} dragEnabled={true}
                    closeOnOutsideClick={false} showTitle={false} width={1000} height={675}>
                    <ScrollView height={575}>
                        <p>Select the Hotel Stay to Merge this Hotel Stay on top of</p>
                        <br />
                        <DataGrid dataSource={this.state.overwritingMergeItem} showBorders={true} allowColumnResizing={true}>

                            <Column caption="Employee Name" dataField="travelerName" groupIndex={0} />
                            <Column caption="Confirmation #" dataField="confirmationNumber" />
                            <Column caption="Status" dataField="status" />
                            <Column caption="Hotel Name" dataField="hotelName" />
                            <Column caption="Check In" dataField="checkIn" dataType="datetime" format="MM/dd/yy, HH:mm" />
                            <Column caption="Check Out" dataField="checkOut" dataType="datetime" format="MM/dd/yy, HH:mm" />
                            <Column caption="Booking Status" dataField="itineraryStatus" />
                        </DataGrid>
                        <br />
                        <DataGrid ref={ref => this.mergeHotelsDataGrid = ref} dataSource={this.state.eligibleMergeHotels} showBorders={true} allowColumnResizing={true}>
                            <FilterRow visible={true} />
                            <Selection mode="single" selectAllMode="allPages" deferred={true} />

                            <Column caption="Employee Name" dataField="travelerName" groupIndex={0} />
                            <Column caption="Confirmation #" dataField="confirmationNumber" />
                            <Column caption="Status" dataField="status" />
                            <Column caption="Hotel Name" dataField="hotelName" />
                            <Column caption="Check In" dataField="checkIn" dataType="datetime" format="MM/dd/yy, HH:mm" />
                            <Column caption="Check Out" dataField="checkOut" dataType="datetime" format="MM/dd/yy, HH:mm" />
                            <Column caption="Booking Status" dataField="itineraryStatus" />
                        </DataGrid>
                    </ScrollView>
                    <br />

                    <div style={{ textAlign: 'center' }}>
                        <Button text="Save" onClick={this.saveEmployeeHotelMerge.bind(this)} />
                        &nbsp;&nbsp;
                        <Button text="Cancel" onClick={this.hideMergeDialog} />
                    </div>
                </Popup>
            </div>
        );
    }
}

class HotelStayDetail extends React.Component {
    constructor(props) {
        //console.log('Detail Props', props);
        super(props);
        this.dataSource = [props.data.row.data];
        this.stayDetail = props.data.row.data.travelerName + "_" + props.data.row.data.hotelName;
    }
    render() {
        return (
            <DataGrid dataSource={this.dataSource} showBorders={true} allowColumnResizing={true} onExporting={this.onExporting}>
                <Export enabled={true} fileName={"hotelStayDetail_" + this.stayDetail} />
                <Column caption="Confirmation Number" dataField="confirmationNumber" />
                <Column caption="Address" dataField="hotelAddress" />
                <Column caption="Phone Number" dataField="hotelPhone" />
                <Column caption="Fax Number" dataField="hotelFax" />
                <Column caption="Rate" dataField="rate" />
                <Column caption="Rooms" dataField="rooms" />
                <Column caption="Room Type" dataField="roomType" />
                <Column caption="Stay Duration" dataField="stayDuration" />
                <Column caption="Cancellation Policy" dataField="cancelationPolicy" />                
            </DataGrid>
        );
    }
}