import React from 'react';
import BaseComponent, { withParams } from '../BaseComponent';
import TabPanel, { Item } from 'devextreme-react/tab-panel';
import Form, { Item as FormItem, Label } from 'devextreme-react/form';
import * as moment from 'moment';

import { WorkOrderResourcesEquipmentContainer } from './WorkOrderResourcesEquipmentContainer.js';
import { WorkOrderResourcesHotelStaysContainer } from './WorkOrderResourcesHotelStaysContainer.js';
import { WorkOrderResourcesCustomersContainer } from './WorkOrderResourcesCustomersContainer.js';
import { WorkOrderResourcesMealsContainer } from '../Inventory/WorkOrderResourcesMealsContainer';
import { WorkOrderResourcesClothesContainer } from '../Inventory/WorkOrderResourcesClothesContainer.js';
import { WorkOrderResourcesOtherContainer } from '../Inventory/WorkOrderResourcesOtherContainer.js';
import { WorkOrderResourcesEmployeesContainer } from './WorkOrderResourcesEmployeesContainer.js';
import { WorkOrderResourcesSupportStaffContainer } from './WorkOrderResourcesSupportStaffContainer.js';
import WorkOrderResourcesGroundTransportationContainer from './WorkOrderResourcesGroundTransportationContainer.js';
import { WorkOrderEmployeeHotelStaysContainer } from './WorkOrderEmployeeHotelStaysContainer.js';
import { WorkOrderEmployeeFlightsContainer } from './WorkOrderEmployeeFlightsContainer.js';
import { WorkOrderCustomerFlightsContainer } from './WorkOrderCustomerFlightsContainer.js';

class WorkOrderResourcesContainer extends BaseComponent {
    static displayName = WorkOrderResourcesContainer.name;

    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            workOrderId: '',
            workOrder: {}
        };
    }

    componentDidMount = async () => {
        super.componentDidMount();
        //console.log('ComponentDidMount');
        //console.log(this.props.params);

        const { id } = this.props.params

        await this.setState({ workOrderId: id });

        await this.fetchData();
    }

    GetComponentPageName = () => {
        return (this.state.workOrder.workOrderNumber ? ("#" + this.state.workOrder.workOrderNumber + " WO Resources") : 'Work Order Resources');
    }

    GetData = async () => {

        var workOrderData = await this.FetchAPI('WorkOrder/' + this.state.workOrderId);

        //console.log('Work Order', workOrderData);

        // ABG: Not sure why I have to do this, but devextreme will not handle offset dates properly
        workOrderData.planStartDate = moment.parseZone(workOrderData.planStartDate).format('yyyy-MM-DDTHH:mm:ss');
        workOrderData.planEndDate = moment.parseZone(workOrderData.planEndDate).format('yyyy-MM-DDTHH:mm:ss');

        await this.setState({
            workOrder: workOrderData
        });
    }

    render() {
        return (
            <div className="container-fluid">
                <h1>Work Order Resources</h1>
                
                <div className="row">
                    <fieldset>
                        <header><b>Work Order</b></header>
                        <br />

                        <Form
                            id="form"
                            colCount={4}
                            formData={this.state.workOrder}>

                            <FormItem dataField="workOrderName" editorOptions={{ readOnly: true }}>
                                <Label text="Name" />
                            </FormItem>

                            <FormItem dataField="workOrderNumber" editorOptions={{ readOnly: true }}>
                                <Label text="Number" />
                            </FormItem>

                            <FormItem dataField="planStartDate" editorType="dxDateBox" editorOptions={{
                                readOnly: true, displayFormat: "MM/dd/yy, HH:mm", type: 'datetime' }}>
                                <Label text="Plan Start Date" />
                            </FormItem>

                            <FormItem dataField="planEndDate" editorType="dxDateBox" editorOptions={{
                                readOnly: true, displayFormat: "MM/dd/yy, HH:mm", type: 'datetime'
                            }}>
                                <Label text="Plan End Date" />
                            </FormItem>
                        </Form>
                    </fieldset>
                </div>

                <div className="row">
                    <fieldset>
                        <header><b>Customers</b></header>
                        <br />

                        <TabPanel>
                            <Item title="Customers">
                                <WorkOrderResourcesCustomersContainer workOrder={this.state.workOrder} />
                            </Item>
                            <Item title="Flights">
                                <WorkOrderCustomerFlightsContainer workOrder={this.state.workOrder} />
                            </Item>
                            <Item title="Hotel Stays">
                                <WorkOrderResourcesHotelStaysContainer workOrder={this.state.workOrder} />
                            </Item>
                            <Item title="Meals" >
                                <WorkOrderResourcesMealsContainer workOrder={this.state.workOrder} />
                            </Item>
                            <Item title="Clothes">
                                <WorkOrderResourcesClothesContainer workOrder={this.state.workOrder} />
                            </Item>
                            <Item title="Other Items" >
                                <WorkOrderResourcesOtherContainer workOrder={this.state.workOrder} />
                            </Item>
                        </TabPanel>
                    </fieldset>
                </div>
                <br />
                <div className="row">
                    <fieldset>
                        <header><b>Resources</b></header>
                        <br />

                        <TabPanel>
                            <Item title="Employee">
                                <WorkOrderResourcesEmployeesContainer workOrder={this.state.workOrder} />
                            </Item>
                            <Item title="Support Staff">
                                <WorkOrderResourcesSupportStaffContainer workOrder={this.state.workOrder} />
                            </Item>
                            <Item title="Flights">
                                <WorkOrderEmployeeFlightsContainer workOrder={this.state.workOrder} />
                            </Item>
                            <Item title="Ground Transportation">
                                <WorkOrderResourcesGroundTransportationContainer workOrder={this.state.workOrder} />
                            </Item>
                            <Item title="Hotel Stays">
                                <WorkOrderEmployeeHotelStaysContainer workOrder={this.state.workOrder} />
                            </Item>
                            <Item title="Equipment">
                                <WorkOrderResourcesEquipmentContainer workOrder={this.state.workOrder} />
                            </Item>
                        </TabPanel>
                    </fieldset>
                </div>
                <br />
            </div>
        );
    }
}

export default withParams(WorkOrderResourcesContainer);